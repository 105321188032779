import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import App from "./App";

function AppRouter() {
  const history = createBrowserHistory()

  return (
    <Router history={history}>
      <App />
    </Router>
  );
}

export default AppRouter;
